
.main{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 80vh;
    margin-left: 25%;
    background-color: aliceblue;
    margin-top: 5%;
   
   
    
}
.top{
   
    background-color: blue;
    color: white;
}

.ca{
   
    padding: 13%;
   
}
.one{
    padding: 3%;
}
.ca input{
    width: 100%;
}
.button{
    background-color: blue;
    border: none;
    color: white;
}
input{
    border: none;
    outline: none;
    border-bottom: 2px solid blue;
}